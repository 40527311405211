import react from 'react';
import  '../Assets/main.css';
import Head from './Head';
import Main from './Main';
import Foot from './Foot';




const Landingpage = () => {
    return (
        <>

            <h3>hi there</h3>
            <Head></Head>
            <Main></Main>
            <Foot></Foot>
        
        

        </>
    );
}

export default Landingpage;
